import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"

export default function FrontrunningInEthereum(): JSX.Element {
  return (
    <Layout>
      <SEO title="Frontrunning In Ethereum" />
      <h1>From Running In Ethereum</h1>
      <p>What is Front Running</p>
    </Layout>
  )
}
